
import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("1234567812345678");
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');

export const Encrypt = (word: string)=>{
    const srcs = CryptoJS.enc.Utf8.parse(word);
   
    const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv,mode:CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7});
   
    return encrypted.toString();
}
   
export const Decrypt = (word: string) =>{
   
    const decrypt = CryptoJS.AES.decrypt(word, key, { iv: iv,mode:CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7});
   
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
   
    return decryptedStr.toString();
}
   