import React, { useState, useCallback, useMemo } from 'react';
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './StethoUpload.css';
import { upload } from '../service/StegoService';
import { useUser } from "../state/UserContext";

const placeholder = require('../assets/placeholder.jpg')


function StethoUpload() {
	const {user} = useUser();

  const [selectedFile, setSelectedFile] = useState<any>();
  const [text, setText] = useState<string>('')
  const [progress, setProgress] = useState<number>()
 
	const changeHandler = useCallback((event:any) => {
		setSelectedFile(event.target.files[0]);
	}, []);

	const handleSubmission = useCallback(async (e:any) => {
		e.preventDefault() //prevent the form from submitting

		await upload(selectedFile, text, (data: any) => {
			setProgress(Math.round((100 * data.loaded) / data.total))
		});

		setSelectedFile(undefined);
		setText('');
		setProgress(0);
	},[selectedFile, text]);

  const image = useMemo(() => { if(!selectedFile){return placeholder}  return URL.createObjectURL(selectedFile)
  }, [selectedFile]);
	
	const enableButton = useMemo(() => {
	  return (selectedFile && text.length > 0)
}, [selectedFile, text]);
	
  return (
 
	  <div className='StethoUpload-Container'>
		  <div className='StethoUpload-Center'>

				  <h4>Welcome <span className='text-primary'>{user?.userName }</span></h4>
				  <p>Select a file</p>
				  <Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Control type="text" placeholder="Enter Content" value={text} onChange={(e:any)=> setText(e?.target.value)} />
						<Form.Text className="text-muted">
						</Form.Text>
				   </Form.Group>

        		<div>
				  <img className='StethoUpload-Image' alt='upload' src={image} />
				  <input className='StethoUpload-UploadInput' type="file" name="file" onChange={changeHandler} />
     			 </div>
				  <ProgressBar now={progress} label={`${progress}%`} visuallyHidden />
				  <button className={'StethoUpload-Button ' + (!enableButton && 'StethoUpload-Button-Disable')}  disabled={!enableButton} onClick={handleSubmission}>Upload</button>
			 </div>
        </div>
  );
}

export default StethoUpload;
