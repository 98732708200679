import React  from 'react';
import { Routes, Route } from "react-router-dom";

import Login from './pages/Login';
import StethoUpload from './pages/StethoUpload';
import MediaList from './pages/MediaList';


interface AppRouterProps{
  isLoaded: boolean 
}
const AppRouter =({isLoaded}: AppRouterProps)=> {

  return (
    <div className="App">
      <h1 className='App-Center mt-2'>Hidden in plain sight!</h1>
      {isLoaded && <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<StethoUpload />} />
        <Route path="media" element={<MediaList />} />
        {/* <Route path="*" element={<Login />} /> */}
      </Routes>}
    </div>
  );
}

export default AppRouter;
