import React, {useState,useEffect,useCallback, useMemo} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { setToken } from './service/APIService';
import { User, UserContext } from './state/UserContext';
import { ToastContainer } from 'react-toastify';
import { useNavigate  } from "react-router-dom";
import {Navbar,Container,Nav,Button} from 'react-bootstrap'
import AppRouter from './AppRouter'


function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>({} as User)
  const [isLoaded, setLoaded] = useState<boolean>(false)

  useEffect(() => { 
		const userString = localStorage.getItem('user');
		if (userString && !user.token) {
			const user = JSON.parse(userString) as User;
      setUser(user)
      setToken(user.token as string)
  
      setTimeout(() => {
        if (window.location.pathname === "/") {
          navigate('/home')
        }
       }, 100)
    }
    setLoaded(true)
	}, [navigate, user])
	
  const hasLoggedIn = useMemo(() => {
    console.log('user', {user,isLoaded })
    return !(user.token === null || user.token === '' || user.token === undefined)
  }, [user,isLoaded]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("user")
    setUser({} as User)
    navigate("/")
  }, [navigate])
  
  return (
    <UserContext.Provider value={{user,setUser}}>
      <div className="App container">
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
              >
              <Nav.Link className='primary' href="/">Home</Nav.Link>
             {hasLoggedIn && <Nav.Link href="/media">Media</Nav.Link>}
            </Nav>
           {hasLoggedIn && <Button variant="outline-success" onClick={handleLogout}>Logout</Button>}
          </Navbar.Collapse>
        </Container>
        </Navbar>
        < AppRouter isLoaded={isLoaded} />
        </div>
        <ToastContainer position="top-center" autoClose={5000} />
      </UserContext.Provider>
  );
}

export default App;
