import axios from 'axios'
import { Decrypt } from './AESService';

export const upload = async (file: any, message: string, progress: Function) => {
    let formData = new FormData()
		formData.append("file", file)
		formData.append("message", message)

		await axios.post("/upload", formData, {
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		  onUploadProgress: (data: any) => {
			//Set the progress value to show the progress bar
			progress && progress(Math.round((100 * data.loaded) / data.total))
		  },
		 })
}

export const decryptImage = async (uri: string) => {
	return new Promise<string>((resolve, reject) => {

		let canvas = document.createElement("canvas");
		let img = new window.Image();
		img.src = uri;
		img.setAttribute('crossOrigin', '');
		img.addEventListener("load", async () => {
			const context = canvas.getContext("2d");
			if (context == null) {
				reject('invalid context')
				return
			}
			const height = img.height;
			const width = img.width;
			canvas.width = width;
			canvas.height = height;
			img && context.drawImage(img, 0, 0);

			let imageData = context.getImageData(0, 0, width, height);
			const data = imageData.data;

			// console.log('myCanvas', {height, width, s: data.length})

			const messageLength = decodeBitsFromPixels(data, 32, 0);
			// console.log('myCanvas', messageLength)
			let byteArray = []
			let pixelIndex = 32;
			for (let i = 0; i < messageLength; i++) {
				const ele = decodeBitsFromPixels(data, 8, pixelIndex)
				byteArray.push(ele);
				pixelIndex += 8
				// console.log('Bytes to string: ', String.fromCharCode(ele), ele, i)
			}
			const bytesString = String.fromCharCode(...byteArray)

			// 3
			const value = await Decrypt(bytesString);
			resolve(value)
			console.log('Bytes to string: ', { bytesString, value })
		});	
	});
}

const  decodeBitsFromPixels = (pixels : Uint8ClampedArray, numberOfBits: number, pixelIndex :number)=> {
	var decodedValue  = 0
	for (let i = 0; i < numberOfBits; i++) {
		const {color} = getPixelFromBits(pixels, pixelIndex);
		const bit = color & 1
		decodedValue |= bit << i
		// console.log("pxie", {bit, color, rgb})
		pixelIndex++
	}
	return decodedValue
}

const getPixelFromBits = (bits: Uint8ClampedArray, index: number) =>{
	const i = index * 4;
	const r = bits[i]
	const b = bits[i+1]
	const g = bits[i+2]
	const a = bits[i+3]
	const rgbColor = (r << 24) + (g << 16) + (b << 8) + a;
	const rgb = (rgbColor >> (8 * 2)) & 0xFF
	// console.log("pxie", {r, b, g, a, i})
	return {color: rgb, rgb:  {r, g, b, a}};
}