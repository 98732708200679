import axios from 'axios'
import { User } from "../state/UserContext";


export const login = async (value: any) => {
    const response = await axios.post("/sign-in", value);
    const isAdmin = response?.data?.isAdmin ?? false;
    if (isAdmin) {
        const userName = (response?.data?.userName ?? "") as string
        const token = (response?.data?.token ?? "") as string
        axios.defaults.headers.common['Authorization'] = token;
        const user: User = {
            userName, token
        }
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    } else {
        throw new Error("Required Admin Permission");
    }
}
export const deleteImage = async (fileName: string) => {
    return axios.get("/delete-image?fileName=" + fileName)
}
export const setToken = (token: string) => {
    axios.defaults.headers.common['Authorization'] = token;

} 