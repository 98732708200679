import { createContext, useContext } from "react";

export interface User{
    userName: string
    token: string
}
export interface UserState{
    user: User
    // setUser: ((user: User) => Void) | User
    setUser(f: User | ((prev: User) => User)): void;

}
export const UserContext = createContext<UserState>({} as UserState)


export const useUser = () => {
    const user = useContext<UserState>(UserContext);
    return user;
}