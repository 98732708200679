import React, {useState, useCallback} from 'react';
import axios from 'axios'
import './MediaList.css'
import { StethoImage } from '../interface';
import { BASE_URL } from '../shared';
import { decryptImage } from '../service/StegoService';
import { deleteImage } from '../service/APIService';
import {  toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
const TrashIcon = require('../assets/trash-bin.png') // Import

const  MediaList =()=> {
	const [list, setList] = useState<StethoImage[]>([])
	
	const fetch = useCallback(async () => {
		const response = await axios.get("/list")
		.catch(e => console.log('err', e));
		setList(response?.data)
	}, [])
	const handleClick = useCallback(async (item: any, uri: string) => {
		const value = (await decryptImage(uri)) ?? ''
		console.log('value,',value)
		if (value.length > 0) {
			toast.success(value)
		}
  }, [])
	
	const handleDelete = useCallback(async (item: any, uri: string) => {
		console.log('item',item)
		confirmAlert({
			customUI: ({ onClose }) => {
			  return (
				<div className='alert-ui'>
				  <h5>Are you sure?</h5>
				  <p>You want to delete this file?</p>
					  <div>
					  <button className="alert-button " onClick={onClose}>No</button>
					  <button
							  className='m-2 alert-button bg-danger text-white'
								onClick={async() => {
									onClose();
									await deleteImage(item.path).catch(e=>{})
									fetch();
					}}
				  >
					Yes, Delete it!
				  </button>
				  </div>
				</div>
			  );
			}
		  });

  }, [fetch])
	
	const renderItem = useCallback((item: any) => {
		const uri = BASE_URL.replace("/api", "") + '/uploads/' + item.path
		return <ImageContent key={uri} item={item} uri={uri} onClick={handleClick} onDelete={handleDelete} />
	}, [handleClick, handleDelete])

	
	React.useEffect(() => { 
	fetch()
  }, [fetch])
	
  return (
	  <div className="App">
		  <div className='MediaList-Container m-3 m-sm-2 d-flex row'>
			  {list.map(renderItem)}
		  </div>
    </div>
  );
}

export default MediaList;

const ImageContent = (props: any) => {
	const handleClick = useCallback((item: any) => {
		props.onClick && props.onClick(props.item, props.uri);
	}, [props])

	const handleDelete= useCallback(() => {
		props.onDelete && props.onDelete(props.item, props.uri);
	}, [props])

	return <div className=' col-xl-2 col-md-3 col-6 ' key={props.uri}>
		<div className='MediaList-Container-Item  col-12'>
			<img className='MediaList-Container-Item-Image' alt='upload' src={props.uri} onClick={handleClick} />
			<div className='d-flex justify-content-end'>
			<div className='m-1 p-1 DeleteIcon'>
				<img  width={25} alt='delete' src={TrashIcon} onClick={handleDelete}></img>
			</div>
			</div>
		</div>
	</div>
}