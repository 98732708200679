import React, {  useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import  * as yup from 'yup';
import axios from 'axios'

import { useFormik } from 'formik';
import './Login.css';
import { useUser, User } from "../state/UserContext";
import { useNavigate  } from "react-router-dom";
import {  toast } from 'react-toastify';
import { login } from '../service/APIService';


const schema = yup.object().shape({
	userName: yup.string().required(),
	password: yup.string().required(),
  });
  

function Login() {
    const navigate = useNavigate();
	const { setUser } = useUser();

	const handleLogin = useCallback(async (values: any) => {
		const user = await login(values).catch(e => {toast.error(e.message ?? 'Invalid user name password');});
		if (user) {
			setUser(user)
			navigate('/home')
		 }
	}, [navigate,setUser])

  const formik = useFormik({
	initialValues: {
		userName: '',
		password: '',
	},
	validationSchema: schema,
	onSubmit: handleLogin,
  });
	
	const handle = useCallback(() => {
		formik.handleSubmit();
	}, [formik])
	
  return (
 
	  <div className='Login-Container'>
		  <div className='Login-Center'>
			<h4 className='mb-3'>Login</h4>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>UserName </Form.Label>
				<Form.Control type="text" name="userName" placeholder="Enter UserName"   onChange={formik.handleChange}  value={formik.values.userName}/>
				{formik.errors.userName && <Form.Text className="Login-Error">
					required Username
				</Form.Text>}
				
			</Form.Group>

			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Password</Form.Label>
				  <Form.Control type="password" placeholder="Password" name="password" onChange={formik.handleChange} value={formik.values.password} />
				  {formik.errors.password && <Form.Text className="Login-Error">
					required password
				</Form.Text>}
			</Form.Group>
			  <div className='text-center'>
			  <Button variant="primary" type="submit" onClick={handle}>
				Submit
			</Button>
			</div>
		  </div>
        </div>
  );
}

export default Login;
